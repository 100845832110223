import React from 'react';
import { connect } from 'react-redux';
import GetSvg from 'vendor/GetSvg';
import api from 'constants/api';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import { GetProfileActions } from 'views/Profile/actions';
import { logoutCompletely } from 'functions/authenticator.func.js';
import withRouter from 'helpers/withRouter';

const Header = ({ location: { pathname }, history, onDidGetProfile }) => (
  <AxiosPageReadyWrapper
    url={api.user.GET_PROFILE}
    onData={(data) => {
      onDidGetProfile(data);
    }}>
    <AxiosPageReadyContext.Consumer>
      {({ data: { first_name, surname, is_vip } }) => (
        <div className="dashboard__header__wrapper">
          <div className="dashboard__header__wrapper__avatar">
            <GetSvg svg="my_profile" wrapperClass="svg--avatar" />
            {is_vip && (
              <GetSvg
                svg="vip"
                wrapperClass="dashboard__header__wrapper__avatar-vip-icon"
              />
            )}
          </div>
          <div className="dashboard__header__wrapper__action-wrapper">
            <div className="edit-profile-wrapper">
              <div className="edit-profile-wrapper__profile-name">
                <span className="mr-2">
                  {first_name}
                  &nbsp;
                  {surname}
                </span>
                {is_vip && (
                  <span className="text-base font-light">
                    (Xclusive Member)
                  </span>
                )}
              </div>
              <div>
                {pathname !== '/profile' ? (
                  <button
                    className="dashboard__button"
                    type="button"
                    onClick={() => history.push('/profile')}>
                    (Edit Profile)
                  </button>
                ) : null}
              </div>
            </div>
            <div className="logout-button-wrapper">
              <button type="button" onClick={logoutCompletely}>
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </AxiosPageReadyContext.Consumer>
  </AxiosPageReadyWrapper>
);

const mapActions = (dispatch) => ({
  onDidGetProfile: (payload) => dispatch(GetProfileActions.success(payload)),
});

export default withRouter(connect(null, mapActions)(Header));
