import React from 'react';

const CustomLink = ({ href, children, className, onClick }) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (onClick) {
      onClick();
    }

    if (href) {
      window.location.href = href;
    }
  };

  return (
    <a href={href} className={className} onClick={handleClick}>
      {children}
    </a>
  );
};

export default CustomLink;
