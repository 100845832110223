export const getInterestsQuery = (userId) => `{
  interests(limit: 40, page: 1, user_id: ${userId}) {
    data {
      id,
      gender,
      title,
      user {
        id
      },
      luxity_styles {
        id,
        name,
      },
      luxity_brands {
        id,
        name
      },
      min_price,
      max_price,
      min_shoe_size {
        id,
        size,
        type
      },
      max_shoe_size {
        id,
        size,
        type
      },
      category_type
    },
    total,
    per_page,
    current_page,
    from,
    to,
    last_page,
    has_more_pages
  }
}`;

export const getShoeSizeQuery = () => `{
  luxity_eu_shoe_sizes {
    id,
    size
  },
  luxity_uk_shoe_sizes {
    id,
    size
  }
}`;

export const getBrandsQuery = (
  limit,
  page,
  sort_by,
  category_type,
  nameFilter,
) => `{
    luxity_brands (limit: ${limit}, page: ${page}, category_type: ${category_type}, filter: {name: {like: "${nameFilter}" } } ){
      data {
        id,
        name,
      },
      total,
      per_page,
      current_page,
      from,
      to,
      last_page,
      has_more_pages
    }
  }`;

export const getStylesQuery = (
  limit,
  page,
  sort_by,
  category_type,
  nameFilter,
) => `{
    luxity_styles (limit: ${limit}, page: ${page}, category_type: ${category_type}, sort_by:"total_products|desc", get_product_totals:true, filter: {style: {like: "${nameFilter}" } } ){
      data {
        id,
        name,
      },
      total,
      per_page,
      current_page,
      from,
      to,
      last_page,
      has_more_pages
    }
  }`;

export const getNotificationOptionsQuery = (userId) => `{
    user (id: ${userId}) {
      email_interest_send_schedule
      whatsapp_interest_send_schedule
      vip
    },
  __type(name:"interest_schedule_enum") {
    enumValues {
      description
      name
    }
  }
}`;
